import { APIurls } from "./constants";
import apiService from ".";
import axiosPayment from "./paymentRequest";

export const ApiRequests = {
  // auth
  login: async (data) => await apiService.post(APIurls.login, data),

  // users
  getUserProfile: async () => await apiService.get(APIurls.profile),
  updateUserProfile: async (data) =>
    await apiService.put(APIurls.profile, data),
  makeRoles: async (data) =>
    await apiService.post(APIurls.profile + "/" + APIurls.role, data),
  userDashboard: async () => await apiService.get(APIurls.userDashboard),
  adminDashboard: async () => await apiService.get(APIurls.adminDashboard),
  getUserCourses: async () =>
    await apiService.get(APIurls.user + "/" + APIurls.allCourses),
  getUserSessionDetails: async (page, limit) =>
    await apiService.get(
      APIurls.allUser +
        "/" +
        APIurls.sessionDetails +
        "?page=" +
        page +
        "&limit=" +
        limit
    ),
  getFetchUserIdAndPaymentDetails: async (id) =>
    await apiService.get(
      APIurls.userId + "/" + id + "/" + APIurls.paymentDetails
    ),
  UpdateUserPaymentStatus: async (data, paymentID, userId) =>
    await apiService.put(
      APIurls.userId + "/" + userId + "/" + APIurls.payment + "/" + paymentID,
      data
    ),

  // lecturer
  getLecturers: async () => await apiService.get(APIurls.lecturerList),
  getAdminList: async () => await apiService.get(APIurls.adminUser),
  getLecturerDetails: async (lecturerId) =>
    await apiService.get(APIurls.lecturerList + "/" + lecturerId),

  // quiz/questions
  addQuiz: async (data) =>
    await apiService.post(APIurls.quiz + "/" + APIurls.allCourses, data),
  getCourseQuiz: async (courseId) =>
    await apiService.get(
      APIurls.quiz + "/" + APIurls.singleCourse + "/" + courseId
    ),
  getPublicCourseQuiz: async (courseId) =>
    await apiService.get(
      APIurls.quiz +
        "/" +
        APIurls.public +
        "/" +
        APIurls.singleCourse +
        "/" +
        courseId
    ),
  addQuestion: async (data, quizId) =>
    await apiService.post(
      APIurls.quiz + "/" + quizId + "/" + APIurls.questions,
      data
    ),
  getQuizQuestions: async (quizId) =>
    await apiService.get(APIurls.quiz + "/" + quizId + "/" + APIurls.questions),

  updateQuizFilled: async (quizId, data) =>
    await apiService.put(
      APIurls.quiz + "/" + quizId + "/" + APIurls.update,
      data
    ),

  getQuestion: async (quizId, questionId) =>
    await apiService.get(
      APIurls.quiz + "/" + quizId + "/" + APIurls.question + "/" + questionId
    ),
  getPublicQuizQuestions: async (courseName, quizShortName) =>
    await apiService.get(
      APIurls.quiz +
        "/" +
        APIurls.public +
        "/" +
        courseName +
        "/" +
        quizShortName +
        "/" +
        APIurls.questions
    ),
  getQuestionCountsByTag: async (quizId) =>
    await apiService.get(
      APIurls.quiz +
        "/" +
        APIurls.tags +
        "/" +
        APIurls.questionCounts +
        "/" +
        quizId
    ),

  quizTagSnapshot: async (quizId, tag) =>
    await apiService.get(
      APIurls.quiz + "/" + APIurls.tags + "/" + quizId + "/" + tag
    ),

  getQuizList: async () =>
    await apiService.get(APIurls.quiz + "/" + APIurls.list),

  handleActiveFlag: async (quizId, data) =>
    await apiService.put(
      APIurls.quiz + "/" + quizId + "/" + APIurls.activeflag,
      data
    ),
  removeQuestion: async (Id, QuestionId) =>
    await apiService.delete(
      APIurls.quiz + "/" + APIurls.questions + "/" + Id + "/" + QuestionId
    ),

  toggleQuestionVisibility: async (Id, QuestionId, data) =>
    await apiService.put(
      APIurls.quiz + "/" + APIurls.questions + "/" + Id + "/" + QuestionId,
      data
    ),

  //assignments/task
  addAssignment: async (data) =>
    await apiService.post(APIurls.assignments + "/" + APIurls.allCourses, data),
  getCourseAssignment: async (courseId) =>
    await apiService.get(
      APIurls.assignments + "/" + APIurls.singleCourse + "/" + courseId
    ),
  handleAssignmentActiveFlag: async (assignmentId, data) =>
    await apiService.put(
      APIurls.assignments + "/" + assignmentId + "/" + APIurls.activeflag,
      data
    ),
  addAssignmentTask: async (data, assignmentId) =>
    await apiService.post(
      APIurls.assignments + "/" + assignmentId + "/" + APIurls.tasks,
      data
    ),
  getAssignmentTasks: async (assignmentId) =>
    await apiService.get(
      APIurls.assignments + "/" + assignmentId + "/" + APIurls.tasks
    ),
  removeTask: async (Id, taskId) =>
    await apiService.delete(APIurls.assignments + "/" + Id + "/" + taskId),

  // interview questions
  getCourseSyllabus: async (courseId) =>
    await apiService.get(
      APIurls.interview + "/" + APIurls.courseChapter + "/" + courseId
    ),
  getInterviewQuestionsCount: async (courseId) =>
    await apiService.get(
      APIurls.interview + "/" + APIurls.questions + "/" + courseId
    ),
  getInterviewQuestions: async (Id) =>
    await apiService.get(
      APIurls.interview + "/" + APIurls.questions + "/" + Id + "/" + APIurls.all
    ),
  getPublicInterviewQuestions: async (courseName, interviewShortName) =>
    await apiService.get(
      APIurls.interview +
        "/" +
        APIurls.questions +
        "/" +
        courseName +
        "/" +
        interviewShortName +
        "/" +
        APIurls.list
    ),

  addInterviewQuestion: async (data) =>
    await apiService.post(
      APIurls.interview + "/" + APIurls.questions + "/" + APIurls.add,
      data
    ),
  handleDeleteQuestion: async (Id, QuestionId) =>
    await apiService.delete(
      APIurls.interview + "/" + APIurls.questions + "/" + Id + "/" + QuestionId
    ),
  handleUpdateQuestion: async (Id, QuestionId, data) =>
    await apiService.put(
      APIurls.interview + "/" + APIurls.questions + "/" + Id + "/" + QuestionId,
      data
    ),
  // quiz response
  addQuestionsResponse: async (data, courseId) =>
    await apiService.post(
      APIurls.quiz +
        "/" +
        APIurls.response +
        "/" +
        APIurls.questions +
        "/" +
        courseId,
      data
    ),

  getQuizResponse: async (courseId) =>
    await apiService.get(
      APIurls.quiz +
        "/" +
        APIurls.response +
        "/" +
        APIurls.questions +
        "/" +
        courseId
    ),
  quizquestionRes: async (action, courseId, quizId) =>
    await apiService.get(
      APIurls.quiz +
        "/" +
        APIurls.response +
        "/" +
        APIurls.questions +
        "/" +
        courseId +
        "/" +
        quizId +
        "/" +
        action
    ),
  updateScoreCard: async (courseId, quizId, data) =>
    await apiService.put(
      APIurls.quiz +
        "/" +
        APIurls.response +
        "/" +
        APIurls.questions +
        "/" +
        courseId +
        "/" +
        quizId +
        "/" +
        APIurls.scoreCard,
      data
    ),
  updateQuizResult: async (courseId, quizId, data) =>
    await apiService.put(
      APIurls.quiz +
        "/" +
        APIurls.response +
        "/" +
        APIurls.questions +
        "/" +
        courseId +
        "/" +
        quizId +
        "/" +
        APIurls.result,
      data
    ),

  // assigment response
  addTasksRes: async (data, courseId) =>
    await apiService.post(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.tasks +
        "/" +
        courseId,
      data
    ),
  getAssignmentRes: async (courseId) =>
    await apiService.get(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.tasks +
        "/" +
        courseId
    ),
  assignmentTasksRes: async (courseId, assignmentId) =>
    await apiService.get(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.tasks +
        "/" +
        courseId +
        "/" +
        assignmentId
    ),
  updateTaskRes: async (resId, formData) =>
    await apiService.put(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.tasks +
        "/" +
        resId +
        "/" +
        APIurls.update,

      formData
    ),
  lecturerTasksReview: async (resId, formData) =>
    await apiService.put(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.lecturer +
        "/" +
        resId +
        "/" +
        APIurls.result,

      formData
    ),
  lecturerAssignmentReview: async (courseId) =>
    await apiService.get(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.lecturer +
        "/" +
        courseId +
        "/" +
        APIurls.review
    ),
  AssignmentsFilteredReview: async (courseId) =>
    await apiService.get(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.lecturer +
        "/" +
        courseId +
        "/" +
        APIurls.assignmentReview
    ),
  getStudentTasks: async (resId, assignmentId) =>
    await apiService.get(
      APIurls.assignments +
        "/" +
        APIurls.response +
        "/" +
        APIurls.lecturer +
        "/" +
        resId +
        "/" +
        assignmentId +
        "/" +
        APIurls.student
    ),

  // liveclass
  joinLiveClass: async (data) =>
    await apiService.post(
      APIurls.liveClass +
        "/" +
        APIurls.students +
        "/" +
        APIurls.markedCompleted,
      data
    ),
  lecturerStudentsPair: async (data) =>
    await apiService.post(
      APIurls.liveClass +
        "/" +
        APIurls.lecturer +
        "/" +
        APIurls.students +
        "/" +
        APIurls.pair,
      data
    ),
  removeLecturerStudentPair: async (liveClassId, studentId, lecturerId) =>
    await apiService.delete(
      APIurls.liveClass +
        "/" +
        liveClassId +
        "/" +
        lecturerId +
        "/" +
        studentId +
        "/" +
        APIurls.pair
    ),

  getAllLiveClasses: async () => await apiService.get(APIurls.liveClass),
  listLiveClasses: async () => await apiService.get(APIurls.listLiveClasses),
  getSingleLiveClasses: async (classId) =>
    await apiService.get(APIurls.liveClass + "/" + classId),
  addLiveClasses: async (formDataObject) =>
    await apiService.post(APIurls.liveClass, formDataObject),
  updateStudentData: async (updatedData) =>
    await apiService.put(
      APIurls.liveClass + "/" + APIurls.updateStudentData,
      updatedData
    ),
  updateLessonCompletion: async (updatedData) =>
    await apiService.put(
      APIurls.liveClass + "/" + APIurls.updateLessonCompletion,
      updatedData
    ),
  updateLiveClass: async (classId, updatedData) =>
    await apiService.put(
      APIurls.liveClass + "/" + classId + "/" + APIurls.update,
      updatedData
    ),

  removeLiveClass: async (Id) =>
    await apiService.delete(APIurls.liveClass + "/" + Id),

  // courses
  getAllCourses: async () => await apiService.get(APIurls.allCourses),
  getAdminCourses: async () => await apiService.get(APIurls.adminCourses),
  searchCourses: async (search) =>
    await apiService.get(APIurls.allCourses + "?search=" + search),
  getSingleCourse: async (courseID) =>
    await apiService.get(APIurls.singleCourse + "/" + courseID),
  getCoursesTags: async () => await apiService.get(APIurls.coursesTags),
  addCourse: async (data) => await apiService.post(APIurls.addCourse, data),
  updateCourse: async (data, courseID) =>
    await apiService.patch(APIurls.updateCourse + "/" + courseID, data),
  deleteCourse: async (courseID) =>
    await apiService.delete(APIurls.deleteCourse + "/" + courseID),
  courseReviews: async () => await apiService.get(APIurls.reviews),
  getCourseID: async (data) => await apiService.post(APIurls.courseID, data),
  randomCourse: async () => await apiService.get(APIurls.randomCourse),
  enrollCourse: async (courseID) =>
    await apiService.post(
      APIurls.profile + "/" + APIurls.singleCourse,
      courseID
    ),
  addCourseRating: async (courseID, data) =>
    await apiService.put(
      APIurls.profile + "/" + APIurls.singleCourse + "/" + courseID,
      data
    ),

  markedCompleted: async (courseID, markedData) =>
    await apiService.post(APIurls.markedCompleted + "/" + courseID, markedData),

  // chapter
  addChapter: async (data) =>
    await apiService.post(APIurls.courseChapter, data),
  updateChapter: async (data) =>
    await apiService.post(APIurls.updateChapter, data),
  removeChapter: async (data) =>
    await apiService.post(APIurls.removeChapter, data),

  // tools
  searchTool: async (data) => await apiService.post(APIurls.searchTool, data),

  // lessons
  addLesson: async (courseID, data) =>
    await apiService.post(
      APIurls.singleCourse + "/" + courseID + "/" + APIurls.singleLesson,
      data
    ),
  getCourseLessons: async (courseID, specifiedFields) =>
    await apiService.get(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.courseLessons +
        "?specifiedFields=" +
        specifiedFields
    ),
  getAllLessons: async () => await apiService.get(APIurls.allLessons),
  deleteCourseLessons: async (courseID, lessonID) =>
    await apiService.delete(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.courseLessons +
        "/" +
        lessonID
    ),
  getLessonDetails: async (courseID, lessonID) =>
    await apiService.get(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.courseLessons +
        "/" +
        lessonID
    ),
  editLesson: async (courseID, lessonID, data) =>
    await apiService.post(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID,
      data
    ),

  updateLessonTitles: async (lessonID, data) =>
    await apiService.put(
      APIurls.singleCourse +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.update +
        "/" +
        APIurls.titles,
      data
    ),

  updateLessonKeywords: async (lessonID, data) =>
    await apiService.put(
      APIurls.singleCourse +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.update +
        "/" +
        APIurls.keywords,
      data
    ),

  // lesson chapter
  addLessonChapter: async (courseID, lessonID, data) =>
    await apiService.post(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.addCourseChapter,
      data
    ),

  deleteLessonChapter: async (courseID, lessonID, chapterID) =>
    await apiService.delete(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.deleteCourseChapter +
        "/" +
        chapterID
    ),

  // lesson video
  addLessonVideo: async (courseID, lessonID, data) =>
    await apiService.post(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.lessonVideo,
      data
    ),

  deleteLessonVideo: async (courseID, lessonID, videoID) =>
    await apiService.delete(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.lessonVideo +
        "/" +
        videoID
    ),

  // lesson query code
  addLessonQuery: async (courseID, lessonID, data) =>
    await apiService.post(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.lessonQuery,
      data
    ),

  deleteLessonQuery: async (courseID, lessonID, queryID) =>
    await apiService.delete(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.lessonQuery +
        "/" +
        queryID
    ),

  // lesson file
  addLessonFile: async (courseID, lessonID, data) =>
    await apiService.post(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.lessonFile,
      data
    ),

  deleteLessonFile: async (courseID, lessonID, fileID, fileName, azureFolder) =>
    await apiService.delete(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.lessonFile +
        "/" +
        fileID +
        "/" +
        APIurls.lessonFileName +
        "/" +
        fileName +
        "/" +
        APIurls.azureFolder +
        "/" +
        azureFolder
    ),

  // related visited lessons
  getRelatedLessons: async (courseID, lessonID) =>
    await apiService.get(
      APIurls.singleCourse +
        "/" +
        courseID +
        "/" +
        APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.related
    ),

  // comments
  getCourseComments: async (courseID) =>
    await apiService.get(
      APIurls.singleCourse + "/" + courseID + "/" + APIurls.comments
    ),

  getCommentReplies: async (courseID) =>
    await apiService.get(
      APIurls.singleCourse + "/" + courseID + "/" + APIurls.replies
    ),
  addComment: async (lessonID, data) =>
    await apiService.post(
      APIurls.singleLesson + "/" + lessonID + "/" + APIurls.comments,
      data
    ),
  getAllComments: async (lessonID) =>
    await apiService.get(
      APIurls.singleLesson + "/" + lessonID + "/" + APIurls.comments
    ),
  deleteComment: async (lessonID, commentID) =>
    await apiService.delete(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID
    ),
  deleteCommentReply: async (lessonID, commentID, replyID) =>
    await apiService.delete(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID +
        "/" +
        APIurls.reply +
        "/" +
        replyID
    ),

  approveComment: async (lessonID, commentID) =>
    await apiService.get(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID +
        "/" +
        APIurls.approve
    ),

  approveReply: async (lessonID, commentID, replyID) =>
    await apiService.get(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID +
        "/" +
        APIurls.reply +
        "/" +
        replyID +
        "/" +
        APIurls.approve
    ),

  likeComments: async (lessonID, commentID) =>
    await apiService.get(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID +
        "/" +
        APIurls.like
    ),
  likeReply: async (lessonID, commentID, replyID) =>
    await apiService.get(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID +
        "/" +
        APIurls.reply +
        "/" +
        replyID +
        "/" +
        APIurls.like
    ),

  addReply: async (lessonID, commentID, data) =>
    await apiService.post(
      APIurls.singleLesson +
        "/" +
        lessonID +
        "/" +
        APIurls.comments +
        "/" +
        commentID +
        "/" +
        APIurls.reply,
      data
    ),

  // contact us
  submitContactUs: async (data) =>
    await apiService.post(APIurls.contactUs, data),
  getALLContactUs: async () => await apiService.get(APIurls.contactUs),
  toggleMessageRead: async (id) =>
    await apiService.put(APIurls.contactUs + "/" + id),

  // testimonial
  getAllTestimonials: async () => await apiService.get(APIurls.allTestimonials),
  getSingleTestimonial: async () => await apiService.get(APIurls.testimonial),
  addTestimonial: async (data) =>
    await apiService.post(APIurls.testimonial, data),
  updateTestimonial: async (data) =>
    await apiService.put(APIurls.testimonial, data),

  getAdminTestimonials: async () =>
    await apiService.get(APIurls.testimonial + "/" + APIurls.testimonials),
  deleteTestimonial: async (testimonialID) =>
    await apiService.delete(
      APIurls.testimonial +
        "/" +
        APIurls.removeTestimonial +
        "/" +
        testimonialID
    ),
  approveTestimonial: async (testimonialID) =>
    await apiService.get(
      APIurls.testimonial + "/" + APIurls.approve + "/" + testimonialID
    ),

  // subscription
  addSubscription: async (data) =>
    await apiService.post(APIurls.subscription, data),
  getSubscription: async () => await apiService.get(APIurls.subscription),
  getScreenPermissions: async () =>
    await apiService.get(APIurls.screenPermissions),

  // forum threads
  getAllCourseThreads: async (courseID) =>
    await apiService.get(APIurls.threads + "/" + courseID + "/all"),
  likeThread: async (threadID) =>
    await apiService.get(APIurls.threads + "/" + threadID + "/" + APIurls.like),
  disLikeThread: async (threadID) =>
    await apiService.get(
      APIurls.threads + "/" + threadID + "/" + APIurls.dislike
    ),
  likeThreadReply: async (threadID, replyId) =>
    await apiService.get(
      APIurls.threads +
        "/" +
        threadID +
        "/" +
        APIurls.reply +
        "/" +
        replyId +
        "/" +
        APIurls.like
    ),
  disLikeThreadReply: async (threadID, replyId) =>
    await apiService.get(
      APIurls.threads +
        "/" +
        threadID +
        "/" +
        APIurls.reply +
        "/" +
        replyId +
        "/" +
        APIurls.dislike
    ),
  getSingleThread: async (threadID) =>
    await apiService.get(APIurls.threads + "/" + threadID),
  searchThread: async (courseID, search) =>
    await apiService.get(
      APIurls.forum +
        "/" +
        courseID +
        "/" +
        APIurls.threads +
        "?search=" +
        search
    ),
  createThread: async (data) =>
    await apiService.post(APIurls.createThread, data),

  deleteThread: async (threadID) =>
    await apiService.delete(APIurls.threads + "/" + threadID),
  approveThread: async (threadID) =>
    await apiService.get(
      APIurls.threads + "/" + threadID + "/" + APIurls.approve
    ),

  // forum answers
  getForumAnswers: async (courseID) =>
    await apiService.get(
      APIurls.threads + "/" + courseID + "/all/" + APIurls.replies
    ),

  deleteForumAnswer: async (threadID, answerID) =>
    await apiService.delete(
      APIurls.threads + "/" + threadID + "/" + APIurls.reply + "/" + answerID
    ),
  approveForumAnswer: async (threadID, answerID) =>
    await apiService.get(
      APIurls.threads +
        "/" +
        threadID +
        "/" +
        APIurls.reply +
        "/" +
        answerID +
        "/" +
        APIurls.approve
    ),

  // query
  getQueryInfo: async (db) =>
    await apiService.get(APIurls.query + "/" + APIurls.QueryInfo + "?db=" + db),
  runQuery: async (data) =>
    await apiService.post(APIurls.query + "/" + APIurls.runQuery, data),
  generateUploadURL: async (data) =>
    await apiService.post(APIurls.generateUploadURL, data),
  generateDownloadURL: async (data) =>
    await apiService.post(APIurls.generateDownloadURL, data),

  //Subscription History
  getSubscribedPlans: async () =>
    await apiService.get(APIurls.subscription + "/" + APIurls.history),
  getPaymentHistory: async () =>
    await apiService.get(
      APIurls.subscription + "/" + APIurls.transactionsHistory
    ),
  sendReceipt: async (id) =>
    await apiService.post(
      APIurls.subscription + "/" + APIurls.sendReceipt + "/" + id
    ),

  //stripe
  processPayment: async (data) =>
    await axiosPayment.post(
      APIurls.subscription + "/" + APIurls.createSession,
      data
    ),
  sessionStorage: async (sessionId) =>
    await axiosPayment.post(
      APIurls.subscription + "/" + APIurls.sessionStorage,
      sessionId
    ),
  sessionMemory: async (sessionId) =>
    await axiosPayment.post(
      APIurls.subscription + "/" + APIurls.sessionMemory,
      sessionId
    ),
  transactions: async (selectedPaymentGateway) =>
    await axiosPayment.get(
      APIurls.subscription +
        "/" +
        APIurls.transactions +
        "/" +
        selectedPaymentGateway
    ),
  getRazorPay: async (data) =>
    await axiosPayment.post(
      APIurls.subscription + "/" + APIurls.razorPay + "/" + APIurls.order,
      data
    ),
};
