import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../layouts/userLayout";
import About from "../screens/about";
import Login from "../screens/auth/login";
import Registeration from "../screens/auth/register";
import Contact from "../screens/contact";
import CoursesDetails from "../screens/courses/coursesDetails";
import CoursesList from "../screens/courses/coursesList";
import CoursesTopic from "../screens/courses/coursesTopic";
import DataEntry from "../screens/adminDataEntry";
import ForumThreadList from "../screens/Forum/forumList";
import CreateThread from "../screens/Forum/forumList/createThread";
import ThreadDetails from "../screens/Forum/forumList/threadQuestion";
import ManageTopic from "../screens/Forum/manageTopic";
import AdminLiveClass from "../screens/adminDataEntry/adminClass/adminLiveClass";
import AdminClassDetails from "../screens/adminDataEntry/adminClass/adminClassDetails";
import TopicDetails from "../screens/Forum/manageTopic/topicDetails";
import Home from "../screens/home";
import PaymentHistory from "../screens/paymentHistory";
import Profile from "../screens/profile";
import ProfileChange from "../screens/profile/profileChange";
import QueryTool from "../screens/queryTool";
import SearchTool from "../screens/searchTool";
import InternationalPlans from "../screens/selectPlan";
import IndiaPlans from "../screens/selectPlan/indiaPlans";
import Services from "../screens/services";
// import Subscription from "../screens/subscription";
// import ConfirmOtp from "../screens/subscription/otp";
// import PaymentDetails from "../screens/subscription/payment";
// import SuccessfulMsg from "../screens/subscription/successful";
import Testimonials from "../screens/testimonials";
import SearchToolResult from "../screens/toolResult";
import AdminReview from "../screens/adminDataEntry/adminReview";
import AdminDashBoard from "../screens/adminDashboard";
import UserDashBoard from "../screens/userDashboard";
import AdminProtectedRoute from "../utils/AdminProtectedRoute";
import UserProtectedRoute from "../utils/UserProtectedRoute";
import CommonProtectedRoute from "../utils/CommonProtectedRoute";
import ManageCourses from "../screens/manageCourses";
import AddCourse from "../screens/manageCourses/addCourses";
import EditCourse from "../screens/manageCourses/addCourses/EditCourse";
import EditTopic from "../screens/Forum/manageTopic/topicDetails/EditTopic";
import ScrollToTop from "../common/ScrollToTop";
import PrivacyPolicy from "../screens/privacyPolicy";
import TermsAndConditions from "../screens/termsAndConditions";
import Internship from "../screens/internship";
import LiveClasses from "../screens/liveClasses";
import JobsListing from "../screens/jobsListing";
import QuizResponse from "../screens/quiz";
import QuizQuestionResponse from "../screens/quiz/questions";
import QuizQuestions from "../screens/adminDataEntry/adminQuizes";
import QuestionsGrid from "../screens/adminDataEntry/adminQuizes/QuestionsGrid";
import AddQuizQuestion from "../screens/adminDataEntry/adminQuizes/AddQuizQuestion";
import EditQuizQuestion from "../screens/adminDataEntry/adminQuizes/EditQuizQuestion";
import AssignmentsTasks from "../screens/adminDataEntry/adminAssignments";
import AddTask from "../screens/adminDataEntry/adminAssignments/addTask";
import AssignmentResponse from "../screens/assignment";
import AssignmentsTaskResponse from "../screens/assignment/tasks";
import LecturerDashBoard from "../screens/lecturerDashboard";
import LecturerProtectedRoute from "../utils/LecturerProtectedRoute";
import AssignmentsReview from "../screens/lecturerDashboard/assignmentsReview";
import TasksReview from "../screens/lecturerDashboard/tasksReview";
import LecturerStudentsPairing from "../screens/adminDataEntry/adminClass/adminClassDetails/lecturerStudentPairing";
// import QuizTags from "../screens/adminDataEntry/adminQuizes/quizTags";
// import TagSnapshot from "../screens/adminDataEntry/adminQuizes/TagSnapshot";
import AdminInterviewQuestions from "../screens/adminDataEntry/adminInterviewQuestions";
import InterviewQuestionsSnapshot from "../screens/adminDataEntry/adminInterviewQuestions/interviewQuestions";
import PublicInterviewQuestionsList from "../screens/interviewQuestion";
import PublicInterviewQuestions from "../screens/interviewQuestion/interviewQuestions";
import NoMatchPage from "../common/NoMatchPage";
import PublicQuizList from "../screens/publicQuiz";
import QuizComponent from "../screens/publicQuiz/publicQuiz";
import Sitemap from "../common/sitemap";
import AdminContactUs from "../screens/adminDataEntry/adminContactUs";
import Cancel from "../stripe/cancel";
import Success from "../stripe/success";
import SQLDatabaseProjects from "../screens/projects";
import RefundPolicy from "../screens/refundPolicy";
import Permissions from "../screens/permissions";
import AdminPaymentProcessing from "../screens/adminDataEntry/adminPaymentProcessing";
import AdminPaymentHistory from "../screens/adminDataEntry/adminPaymentHistory";
import IntroPage from "../screens/introPage";
import WhatsAppSupport from "../screens/auth/whatsapp-support";
import UpdateTopicTitle from "../screens/Forum/manageTopic/topicDetails/UpdateTopicTitle";
import UpdateTopicKeywords from "../screens/Forum/manageTopic/topicDetails/UpdateTopicKeywords";
import AdminUserSessionDetails from "../screens/adminDataEntry/adminUserSession";
import EditUserSessionDetails from "../screens/adminDataEntry/adminUserSession/editUserSession";
// import PdfReader from "../screens/pdfReader";

const AllRouts = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route  exact path="/login" element={<Login />} />
        <Route  exact  path="/register" element={<Registeration />} />
        <Route  exact  path="/whatsapp-support" element={<WhatsAppSupport />} />
        <Route element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/beginners_online_sql_training_course_learn_sql_certification" element={<IntroPage />} />
          {/* <Route exact path="/learn_sql_beginners_online_sql_training_course.pdf" element={<PdfReader />} /> */}
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/terms&conditions" element={<TermsAndConditions />} />
          <Route exact path="/sql_power_bi_software_it_job_internship" element={<Internship />} />
          <Route exact path="/learn_sql_class_online_sql_training_course" element={<LiveClasses />} />
          <Route exact path="/jobslisting" element={<JobsListing />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/learn_sql_power_bi_analytics_online_training_cost" element={<InternationalPlans />} />
          <Route exact path="/learn_sql_power_bi_analytics_online_training_cost_india" element={<IndiaPlans />} />
          <Route exact path="/sitemap" element={<Sitemap />} />
          <Route exact path="/refund-policy" element={<RefundPolicy />} />
          
          {/* Interview Questions */}
          <Route exact path="/:courseName/database_interview_questions" element={<PublicInterviewQuestionsList />} />
          <Route exact path="/interview_questions" element={<PublicInterviewQuestions />} />

          {/* Quiz Questions */}
          <Route exact path="/:courseName/database_quiz_questions" element={<PublicQuizList />} />
          <Route exact path="/quiz_questions" element={<QuizComponent />} />

          {/* Public Projects */}
          <Route exact path="/sql_database_projects" element={<SQLDatabaseProjects />} />


          {/* CoursesList */}
          <Route exact path="/courses/sql_database_programming_tutorial_courses" element={<CoursesList />} />
          <Route exact path="/course_detail" element={<CoursesDetails />} />
          <Route exact path="/:courseName/:chapterName/:lessonName" element={<CoursesTopic />} />

          <Route exact path="/online_sql_query_editor_execution" element={<QueryTool />} />
          <Route exact path="/search" element={<SearchTool />} />
          <Route exact path="/result" element={<SearchToolResult />} />

          {/* Services */}
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/forum-threads" element={<ForumThreadList />} />
          <Route exact path="/forum/:forumID" element={<ThreadDetails />} />

          {/* Testimonials */}
          <Route exact path="/testimonials" element={<Testimonials />} />

          {/* Subscription */}
          {/* <Route exact path="/subscription" element={<Subscription />} /> */}
          {/* <Route exact path="/subscription/payment" element={<PaymentDetails />} /> */}
          {/* <Route exact path="/subscription/confirmotp" element={<ConfirmOtp />} /> */}
          {/* <Route exact path="/subscription/successful" element={<SuccessfulMsg />} /> */}

          {/* User Protected Routes */}
          <Route element={<UserProtectedRoute />}>
            <Route exact path="/user/dashboard" element={<UserDashBoard />} />
            <Route exact path="/payment-history" element={<PaymentHistory />} />
            <Route exact path="/permissions" element={<Permissions />} />
            <Route exact path="/quiz/response/:courseId/questions" element={<QuizResponse />} />
            <Route exact path="/quiz/response/:courseId/:quizId/question" element={<QuizQuestionResponse />} />
            <Route exact path="/assignment/response/:courseId/tasks" element={< AssignmentResponse />} />
            <Route exact path="/assignment/response/:courseId/:assignmentId/task" element={<AssignmentsTaskResponse />} />
          </Route>

          {/* Common Protected Routes */}
          <Route element={<CommonProtectedRoute />}>        
            <Route exact path="/forum/add-question" element={<CreateThread />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profilechange" element={<ProfileChange />} />
            <Route exact path="/success" element={<Success />} />
            <Route exact path="/cancel" element={<Cancel />} />
          </Route>

          {/* Lecturer Protected Routes */}
          <Route element={<LecturerProtectedRoute />}>            
            <Route exact path="/lecturer/dashboard" element={<LecturerDashBoard />} />
            <Route exact path="/lecturer/assignments/:courseId/review" element={<AssignmentsReview />} />
            <Route exact path="/lecturer/assignments/:resId/:assignmentId/tasks/review" element={<TasksReview />} />
          </Route>

          {/* Admin Protected Routes */}
          <Route element={<AdminProtectedRoute />}>
            <Route exact path="/admin/dashboard" element={<AdminDashBoard />} />
            <Route exact path="/admin/user-session-details" element={<AdminUserSessionDetails />} />
            <Route exact path="/admin/user-session/:userId/payment-details" element={<EditUserSessionDetails />} />
            <Route exact path="/admin/payment-processing" element={<AdminPaymentProcessing />} />
            <Route exact path="/admin/payment-history" element={<AdminPaymentHistory />} />
            <Route exact path="/admin/contact_us" element={<AdminContactUs />} />
            <Route exact path="/admin/lecturer/students/:classId/pair" element={<LecturerStudentsPairing />} />
            <Route exact path="/admin/review" element={<AdminReview />} />
            <Route exact path="/admin/interview/questions" element={<AdminInterviewQuestions />} />
            <Route exact path="/admin/interview/questions/:Id/:name/snapshot" element={<InterviewQuestionsSnapshot />} />
            <Route exact path="/admin/topic" element={<ManageTopic />} />
            <Route exact path="/admin/topic/title-update" element={<UpdateTopicTitle />} />
            <Route exact path="/admin/topic/keywords-update" element={<UpdateTopicKeywords />} />
            <Route exact path="/admin/quiz/questions" element={<QuizQuestions />} />
            <Route exact path="/admin/quiz/:quizId/questions" element={<QuestionsGrid />} />
            <Route exact path="/admin/quiz/:quizId/add-question" element={<AddQuizQuestion />} />
            <Route exact path="/admin/quiz/:quizId/edit-question/:questionId" element={<EditQuizQuestion />} />
            {/* <Route exact path="/admin/quiz/:quizId/tags" element={<QuizTags />} />            
            <Route exact path="/admin/quiz/tags/:quizId/:tag" element={<TagSnapshot />} /> */}
            <Route exact path="/admin/assignment/tasks" element={<AssignmentsTasks />} />
            <Route exact path="/admin/assignment/:assignmentId/tasks" element={<AddTask/>} />
            <Route exact path="/admin/liveclass" element={<AdminLiveClass />} />
            <Route exact path="/admin/classdetails/:classId"  element={<AdminClassDetails />} />
            <Route exact path="/admin/topic/add-topic/:courseId" element={<TopicDetails />} />
            <Route exact path="/admin/topic/edit/:courseId/:lessonID" element={<EditTopic />} />
            <Route exact path="/admin/chapters" element={<DataEntry />} />
            <Route exact path="/admin/courses" element={<ManageCourses />} />
            <Route exact path="/admin/courses/add-course" element={<AddCourse />} />
            <Route exact path="/admin/courses/edit/:courseId" element={<EditCourse />} />
          </Route>
        </Route>
        <Route   path="*" element={<NoMatchPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRouts;
