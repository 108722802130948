export const basePath = process.env.REACT_APP_API_URL;

export const APIurls = {
  // auth
  login: "auth/login",

  // user
  profile: "auth/profile",
  userDashboard: "auth/profile/courses",
  adminDashboard: "courses/count",
  user: "auth/user",
  adminUser: "auth/admin/users",
  screenPermissions: "auth/screen/permissions",

  // User Session
  allUser: "all-users",
  userId: "userId",
  role: "role",
  sessionDetails: "info-session-details",

  // lecturerList
  lecturerList: "auth/lecturer",

  //assignment
  assignments: "assignments",
  tasks: "tasks",
  update: "update",
  titles: "titles",
  keywords: "keywords",
  review: "review",
  lecturer: "lecturer",
  student: "student",
  pair: "pair",
  assignmentReview: "assignmentReview",

  //quiz
  quiz: "quiz",
  response: "response",
  scoreCard: "scoreCard",
  result: "result",
  list: "list",
  tags: "tags",
  public: "public",
  questionCounts: "questionCounts",
  questionCount: "questionCount",
  activeflag: "activeflag",

  //questions
  questions: "questions",
  question: "question",

  //interview
  add: "add",
  interview: "interview",
  all: "all",

  // liveclasses
  liveClass: "liveclasses",
  listLiveClasses: "/liveclasses/list",
  students: "students",
  updateStudentData: "updateStudentData",
  updateLessonCompletion: "updateLessonCompletion",

  // tools
  searchTool: "search-tool",

  // courses
  allCourses: "courses",
  singleCourse: "course",
  coursesTags: "courses/tags",
  addCourse: "course",
  updateCourse: "course",
  adminCourses: "courses",
  deleteCourse: "course",
  reviews: "reviews",
  markedCompleted: "enrollment",

  // chapter
  courseChapter: "course/chapter",
  updateChapter: "course/chapter",
  removeChapter: "course/chapter",

  // lessons
  courseLessons: "lesson",
  singleLesson: "lesson",
  lessonVideo: "video",
  addCourseChapter: "courseChapter",
  deleteCourseChapter: "courseChapter",
  lessonQuery: "code",
  lessonFile: "file",
  lessonFileName: "filename",
  azureFolder: "azureFolder",
  allLessons: "lessons",
  courseID: "courses/id",
  randomCourse: "courses/random",
  related: "related",

  // comments
  comments: "comments",
  reply: "reply",
  replies: "replies",

  // approval
  approve: "approve",

  // like
  like: "like",
  dislike: "dislike",

  //contact us
  contactUs: "contact-us",

  // testimonials
  allTestimonials: "testimonial/all",
  testimonial: "testimonial",
  testimonials: "testimonials",
  removeTestimonial: "remove",

  // subscription
  subscription: "subscription",
  razorPay: "razorPay",
  order: "order",
  payment: "payment",
  paymentDetails: "payment-details",
  sessionStorage: "session-storage",
  sessionMemory: "session-memory",
  sendReceipt: "send_receipt",

  // forum questions
  forum: "forum",
  transactions: "transactions",
  threads: "threads",
  thread: "thread",
  createThread: "forum/thread/create",

  // forum answers
  answers: "answers",

  // query
  query: "query",
  QueryInfo: "info",
  runQuery: "run",

  // upload urls
  generateUploadURL: "generate-Upload-URL",
  generateDownloadURL: "generate-Download-URL",

  // stripe
  createSession: "create-checkout-session",
  history: "history",
  transactionsHistory: "transactions-history",
};
